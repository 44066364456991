import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDateTime: any;
  Long: any;
  Upload: any;
};

export type ActOnBehalfOfToken = {
  __typename?: 'ActOnBehalfOfToken';
  businessAccount: BusinessAccountDto;
  token: Scalars['String'];
};

export type BotsquadCredentialsDto = {
  __typename?: 'BotsquadCredentialsDto';
  botId: Scalars['String'];
};

export type BusinessAccountDto = {
  __typename?: 'BusinessAccountDto';
  id: Scalars['Long'];
  name: Scalars['String'];
};

export type CampaignDto = {
  __typename?: 'CampaignDto';
  createdOn: Scalars['LocalDateTime'];
  id: Scalars['Long'];
  name: Scalars['String'];
};

export type CreateBusinessAccountInput = {
  botsquadBearerToken: Scalars['String'];
  botsquadBotId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCampaignInput = {
  campaignName?: InputMaybe<Scalars['String']>;
  templateId: Scalars['Long'];
};

export type CreateTemplateBodyComponentInput = {
  text: Scalars['String'];
};

export type CreateTemplateButtonComponentInput = {
  text: Scalars['String'];
  type: TemplateButtonType;
  value?: InputMaybe<Scalars['String']>;
};

export enum CreateTemplateCategoryInput {
  Marketing = 'MARKETING'
}

export type CreateTemplateComponentsInput = {
  body?: InputMaybe<CreateTemplateBodyComponentInput>;
  buttons?: InputMaybe<Array<CreateTemplateButtonComponentInput>>;
};

export type CreateTemplateInput = {
  category: CreateTemplateCategoryInput;
  components: CreateTemplateComponentsInput;
  language: CreateTemplateLanguageInput;
  uniqueName: Scalars['String'];
};

export enum CreateTemplateLanguageInput {
  Nl = 'NL'
}

export type CreateUserForBusinessAccountInput = {
  businessAccountId: Scalars['Long'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DeleteTemplateInput = {
  id: Scalars['Long'];
};

export type DetailedBusinessAccountDto = {
  __typename?: 'DetailedBusinessAccountDto';
  botsquadCredentials?: Maybe<BotsquadCredentialsDto>;
  id: Scalars['Long'];
  name: Scalars['String'];
  users?: Maybe<Array<UserDto>>;
};

export type DetailedTemplateDto = {
  __typename?: 'DetailedTemplateDto';
  bodyComponent: TemplateBodyComponentDto;
  buttonComponents: Array<TemplateButtonComponentDto>;
  id: Scalars['Long'];
  name: Scalars['String'];
  status: TemplateStatus;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Issue a new ActOnBehalfOf-token to be used */
  createActOnBehalfOfToken: ActOnBehalfOfToken;
  /** Create a new business account */
  createBusinessAccount: BusinessAccountDto;
  /** Create a new campaign */
  createCampaign: CampaignDto;
  /** Create a new message template */
  createTemplate: TemplateDto;
  /** Create a new user for the business account with given ID */
  createUserForBusinessAccount: UserDto;
  /** Delete a template */
  deleteTemplateById: TemplateDto;
};


export type MutationCreateActOnBehalfOfTokenArgs = {
  businessAccountId: Scalars['Long'];
};


export type MutationCreateBusinessAccountArgs = {
  input: CreateBusinessAccountInput;
};


export type MutationCreateCampaignArgs = {
  data: Scalars['Upload'];
  input: CreateCampaignInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateUserForBusinessAccountArgs = {
  input: CreateUserForBusinessAccountInput;
};


export type MutationDeleteTemplateByIdArgs = {
  input: DeleteTemplateInput;
};

export type PaginatedBusinessAccountsDto = PaginationOutput & {
  __typename?: 'PaginatedBusinessAccountsDto';
  page: Scalars['Int'];
  rowCount: Scalars['Long'];
  rows: Array<BusinessAccountDto>;
};

export type PaginatedCampaignsDto = PaginationOutput & {
  __typename?: 'PaginatedCampaignsDto';
  page: Scalars['Int'];
  rowCount: Scalars['Long'];
  rows: Array<CampaignDto>;
};

export type PaginatedTemplatesDto = PaginationOutput & {
  __typename?: 'PaginatedTemplatesDto';
  page: Scalars['Int'];
  rowCount: Scalars['Long'];
  rows: Array<TemplateDto>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PaginationOutput = {
  page: Scalars['Int'];
  rowCount: Scalars['Long'];
};

export type Query = {
  __typename?: 'Query';
  /** Get the details for the authenticated user, or `null` if unauthenticated */
  authenticatedUser?: Maybe<UserDto>;
  /** Fetch a specific business account by it's ID */
  getBusinessAccountById: DetailedBusinessAccountDto;
  /** Get a paginated list of business accounts, optionally filtered by a name. */
  getBusinessAccounts: PaginatedBusinessAccountsDto;
  /** Get a paginated list of campaigns */
  getCampaigns: PaginatedCampaignsDto;
  /** Get a single template with all details by it's ID */
  getTemplateById: DetailedTemplateDto;
  /** Get a paginated list of templates */
  getTemplates: PaginatedTemplatesDto;
  /** Very simple ping-pong call to test basic GraphQL functionality */
  ping: Scalars['String'];
};


export type QueryGetBusinessAccountByIdArgs = {
  id: Scalars['Long'];
};


export type QueryGetBusinessAccountsArgs = {
  nameFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetCampaignsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetTemplateByIdArgs = {
  id: Scalars['Long'];
};


export type QueryGetTemplatesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RoleDto = {
  __typename?: 'RoleDto';
  id: Scalars['Long'];
  name: Scalars['String'];
};

export type TemplateBodyComponentDto = {
  __typename?: 'TemplateBodyComponentDto';
  text: Scalars['String'];
};

export type TemplateButtonComponentDto = {
  __typename?: 'TemplateButtonComponentDto';
  text: Scalars['String'];
  type: TemplateButtonType;
  value?: Maybe<Scalars['String']>;
};

export enum TemplateButtonType {
  PhoneNumber = 'PHONE_NUMBER',
  QuickReply = 'QUICK_REPLY',
  Url = 'URL'
}

export type TemplateDto = {
  __typename?: 'TemplateDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  status: TemplateStatus;
};

export enum TemplateStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type UserDto = {
  __typename?: 'UserDto';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Long'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<RoleDto>>;
};

export type GetCampaignsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetCampaignsQuery = { __typename?: 'Query', getCampaigns: { __typename?: 'PaginatedCampaignsDto', page: number, rowCount: any, rows: Array<{ __typename?: 'CampaignDto', id: any, createdOn: any, name: string }> } };

export type GetBusinessAccountsQueryVariables = Exact<{
  input?: InputMaybe<PaginationInput>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type GetBusinessAccountsQuery = { __typename?: 'Query', getBusinessAccounts: { __typename?: 'PaginatedBusinessAccountsDto', page: number, rowCount: any, rows: Array<{ __typename?: 'BusinessAccountDto', id: any, name: string }> } };

export type GetTemplatesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetTemplatesQuery = { __typename?: 'Query', getTemplates: { __typename?: 'PaginatedTemplatesDto', page: number, rowCount: any, rows: Array<{ __typename?: 'TemplateDto', id: any, name: string, status: TemplateStatus }> } };

export type GetTemplateByIdQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTemplateByIdQuery = { __typename?: 'Query', getTemplateById: { __typename?: 'DetailedTemplateDto', id: any, name: string, status: TemplateStatus, bodyComponent: { __typename?: 'TemplateBodyComponentDto', text: string }, buttonComponents: Array<{ __typename?: 'TemplateButtonComponentDto', type: TemplateButtonType, text: string, value?: string | null }> } };

export type AuthenticatedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthenticatedUserQuery = { __typename?: 'Query', authenticatedUser?: { __typename?: 'UserDto', id: any, firstName: string, lastName: string, email: string, roles?: Array<{ __typename?: 'RoleDto', id: any, name: string }> | null } | null };

export type CreateActOnBehalfOfTokenMutationVariables = Exact<{
  businessAccountId: Scalars['Long'];
}>;


export type CreateActOnBehalfOfTokenMutation = { __typename?: 'Mutation', createActOnBehalfOfToken: { __typename?: 'ActOnBehalfOfToken', token: string, businessAccount: { __typename?: 'BusinessAccountDto', id: any, name: string } } };

export type CreateBusinessAccountMutationVariables = Exact<{
  input: CreateBusinessAccountInput;
}>;


export type CreateBusinessAccountMutation = { __typename?: 'Mutation', createBusinessAccount: { __typename?: 'BusinessAccountDto', id: any, name: string } };

export type CreateUserForBusinessAccountMutationVariables = Exact<{
  input: CreateUserForBusinessAccountInput;
}>;


export type CreateUserForBusinessAccountMutation = { __typename?: 'Mutation', createUserForBusinessAccount: { __typename?: 'UserDto', id: any, firstName: string, lastName: string, email: string } };

export type GetBusinessAccountByIdQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetBusinessAccountByIdQuery = { __typename?: 'Query', getBusinessAccountById: { __typename?: 'DetailedBusinessAccountDto', id: any, name: string, botsquadCredentials?: { __typename?: 'BotsquadCredentialsDto', botId: string } | null, users?: Array<{ __typename?: 'UserDto', id: any, firstName: string, lastName: string, email: string }> | null } };

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
  data: Scalars['Upload'];
}>;


export type CreateCampaignMutation = { __typename?: 'Mutation', createCampaign: { __typename?: 'CampaignDto', id: any, createdOn: any } };

export type DeleteTemplateMutationVariables = Exact<{
  input: DeleteTemplateInput;
}>;


export type DeleteTemplateMutation = { __typename?: 'Mutation', deleteTemplateById: { __typename?: 'TemplateDto', id: any, name: string, status: TemplateStatus } };

export type CreateTemplateMutationVariables = Exact<{
  input: CreateTemplateInput;
}>;


export type CreateTemplateMutation = { __typename?: 'Mutation', createTemplate: { __typename?: 'TemplateDto', id: any, name: string, status: TemplateStatus } };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename?: 'Query', ping: string };


export const GetCampaignsDocument = gql`
    query GetCampaigns($pagination: PaginationInput) {
  getCampaigns(pagination: $pagination) {
    page
    rows {
      id
      createdOn
      name
    }
    rowCount
  }
}
    `;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
      }
export function useGetCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>;
export const GetBusinessAccountsDocument = gql`
    query GetBusinessAccounts($input: PaginationInput, $name: String) {
  getBusinessAccounts(pagination: $input, nameFilter: $name) {
    rows {
      id
      name
    }
    page
    rowCount
  }
}
    `;

/**
 * __useGetBusinessAccountsQuery__
 *
 * To run a query within a React component, call `useGetBusinessAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetBusinessAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>(GetBusinessAccountsDocument, options);
      }
export function useGetBusinessAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>(GetBusinessAccountsDocument, options);
        }
export type GetBusinessAccountsQueryHookResult = ReturnType<typeof useGetBusinessAccountsQuery>;
export type GetBusinessAccountsLazyQueryHookResult = ReturnType<typeof useGetBusinessAccountsLazyQuery>;
export type GetBusinessAccountsQueryResult = Apollo.QueryResult<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>;
export const GetTemplatesDocument = gql`
    query GetTemplates($pagination: PaginationInput) {
  getTemplates(pagination: $pagination) {
    page
    rows {
      id
      name
      status
    }
    rowCount
  }
}
    `;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
      }
export function useGetTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const GetTemplateByIdDocument = gql`
    query GetTemplateById($id: Long!) {
  getTemplateById(id: $id) {
    id
    name
    status
    bodyComponent {
      text
    }
    buttonComponents {
      type
      text
      value
    }
  }
}
    `;

/**
 * __useGetTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>(GetTemplateByIdDocument, options);
      }
export function useGetTemplateByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>(GetTemplateByIdDocument, options);
        }
export type GetTemplateByIdQueryHookResult = ReturnType<typeof useGetTemplateByIdQuery>;
export type GetTemplateByIdLazyQueryHookResult = ReturnType<typeof useGetTemplateByIdLazyQuery>;
export type GetTemplateByIdQueryResult = Apollo.QueryResult<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>;
export const AuthenticatedUserDocument = gql`
    query AuthenticatedUser {
  authenticatedUser {
    id
    firstName
    lastName
    email
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatedUserQuery(baseOptions?: Apollo.QueryHookOptions<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, options);
      }
export function useAuthenticatedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, options);
        }
export type AuthenticatedUserQueryHookResult = ReturnType<typeof useAuthenticatedUserQuery>;
export type AuthenticatedUserLazyQueryHookResult = ReturnType<typeof useAuthenticatedUserLazyQuery>;
export type AuthenticatedUserQueryResult = Apollo.QueryResult<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>;
export const CreateActOnBehalfOfTokenDocument = gql`
    mutation CreateActOnBehalfOfToken($businessAccountId: Long!) {
  createActOnBehalfOfToken(businessAccountId: $businessAccountId) {
    token
    businessAccount {
      id
      name
    }
  }
}
    `;
export type CreateActOnBehalfOfTokenMutationFn = Apollo.MutationFunction<CreateActOnBehalfOfTokenMutation, CreateActOnBehalfOfTokenMutationVariables>;

/**
 * __useCreateActOnBehalfOfTokenMutation__
 *
 * To run a mutation, you first call `useCreateActOnBehalfOfTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActOnBehalfOfTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActOnBehalfOfTokenMutation, { data, loading, error }] = useCreateActOnBehalfOfTokenMutation({
 *   variables: {
 *      businessAccountId: // value for 'businessAccountId'
 *   },
 * });
 */
export function useCreateActOnBehalfOfTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateActOnBehalfOfTokenMutation, CreateActOnBehalfOfTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActOnBehalfOfTokenMutation, CreateActOnBehalfOfTokenMutationVariables>(CreateActOnBehalfOfTokenDocument, options);
      }
export type CreateActOnBehalfOfTokenMutationHookResult = ReturnType<typeof useCreateActOnBehalfOfTokenMutation>;
export type CreateActOnBehalfOfTokenMutationResult = Apollo.MutationResult<CreateActOnBehalfOfTokenMutation>;
export type CreateActOnBehalfOfTokenMutationOptions = Apollo.BaseMutationOptions<CreateActOnBehalfOfTokenMutation, CreateActOnBehalfOfTokenMutationVariables>;
export const CreateBusinessAccountDocument = gql`
    mutation CreateBusinessAccount($input: CreateBusinessAccountInput!) {
  createBusinessAccount(input: $input) {
    id
    name
  }
}
    `;
export type CreateBusinessAccountMutationFn = Apollo.MutationFunction<CreateBusinessAccountMutation, CreateBusinessAccountMutationVariables>;

/**
 * __useCreateBusinessAccountMutation__
 *
 * To run a mutation, you first call `useCreateBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessAccountMutation, { data, loading, error }] = useCreateBusinessAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessAccountMutation, CreateBusinessAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessAccountMutation, CreateBusinessAccountMutationVariables>(CreateBusinessAccountDocument, options);
      }
export type CreateBusinessAccountMutationHookResult = ReturnType<typeof useCreateBusinessAccountMutation>;
export type CreateBusinessAccountMutationResult = Apollo.MutationResult<CreateBusinessAccountMutation>;
export type CreateBusinessAccountMutationOptions = Apollo.BaseMutationOptions<CreateBusinessAccountMutation, CreateBusinessAccountMutationVariables>;
export const CreateUserForBusinessAccountDocument = gql`
    mutation CreateUserForBusinessAccount($input: CreateUserForBusinessAccountInput!) {
  createUserForBusinessAccount(input: $input) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type CreateUserForBusinessAccountMutationFn = Apollo.MutationFunction<CreateUserForBusinessAccountMutation, CreateUserForBusinessAccountMutationVariables>;

/**
 * __useCreateUserForBusinessAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserForBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserForBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserForBusinessAccountMutation, { data, loading, error }] = useCreateUserForBusinessAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserForBusinessAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserForBusinessAccountMutation, CreateUserForBusinessAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserForBusinessAccountMutation, CreateUserForBusinessAccountMutationVariables>(CreateUserForBusinessAccountDocument, options);
      }
export type CreateUserForBusinessAccountMutationHookResult = ReturnType<typeof useCreateUserForBusinessAccountMutation>;
export type CreateUserForBusinessAccountMutationResult = Apollo.MutationResult<CreateUserForBusinessAccountMutation>;
export type CreateUserForBusinessAccountMutationOptions = Apollo.BaseMutationOptions<CreateUserForBusinessAccountMutation, CreateUserForBusinessAccountMutationVariables>;
export const GetBusinessAccountByIdDocument = gql`
    query GetBusinessAccountById($id: Long!) {
  getBusinessAccountById(id: $id) {
    id
    name
    botsquadCredentials {
      botId
    }
    users {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetBusinessAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetBusinessAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessAccountByIdQuery, GetBusinessAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessAccountByIdQuery, GetBusinessAccountByIdQueryVariables>(GetBusinessAccountByIdDocument, options);
      }
export function useGetBusinessAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessAccountByIdQuery, GetBusinessAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessAccountByIdQuery, GetBusinessAccountByIdQueryVariables>(GetBusinessAccountByIdDocument, options);
        }
export type GetBusinessAccountByIdQueryHookResult = ReturnType<typeof useGetBusinessAccountByIdQuery>;
export type GetBusinessAccountByIdLazyQueryHookResult = ReturnType<typeof useGetBusinessAccountByIdLazyQuery>;
export type GetBusinessAccountByIdQueryResult = Apollo.QueryResult<GetBusinessAccountByIdQuery, GetBusinessAccountByIdQueryVariables>;
export const CreateCampaignDocument = gql`
    mutation CreateCampaign($input: CreateCampaignInput!, $data: Upload!) {
  createCampaign(input: $input, data: $data) {
    id
    createdOn
  }
}
    `;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($input: DeleteTemplateInput!) {
  deleteTemplateById(input: $input) {
    id
    name
    status
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    id
    name
    status
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const PingDocument = gql`
    query Ping {
  ping
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const possibleTypes = {"PaginationOutput":["PaginatedBusinessAccountsDto","PaginatedCampaignsDto","PaginatedTemplatesDto"]};
